<template>
  <v-container id="user-tables" fluid tag="section">
    <base-material-card icon="mdi-clipboard-text" class="px-5 py-3">
      <v-card-title>
        <v-dialog v-model="dialogSampleCode" persistent max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              disabled
              color="#1b5e20"
              class="white--text"
              elevation="2"
              v-bind="attrs"
              v-on="on"
              >Add Sample Code</v-btn
            >
          </template>

          <v-card>
            <v-card-title>
              <span class="text-h5">Sample Code</span>
            </v-card-title>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-select
                      v-model="editedItem.text"
                      :items="iot_selects"
                      attach
                      chips
                      label="Select Service iot"
                      required
                      ref="iots_selectReff"
                      item-text="text"
                      item-value="id"
                    >
                    </v-select>
                  </v-row>

                  <v-row>
                    <v-file-input truncate-length="15" ref="sampleCode" required>
                    </v-file-input>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-form>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="#1b5e20" class="white--text" text @click="closeSampleCode">
                Cancel
              </v-btn>
              <v-btn color="#1b5e20" class="white--text" elevation="2"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-title>
      <v-data-table
        ref="vDataTableSampleCode"
        :headers="headersSampleCode"
        :items="iot_selects"
        hide-default-footer
        class="elevation-0"
      >
        <template #item="{ item, index, headers }">
          <tr>
            <td>
              {{ index + 1 }}
            </td>
            <td>
              {{ item.text }}
            </td>
            <td>
              <v-text v-if="item.path_sample_code == null"> Empty </v-text>
              <v-text v-else> Available </v-text>
            </td>
            <td>
              <v-icon small class="mr-2"> mdi-pencil </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
    </base-material-card>
    <base-material-card icon="mdi-clipboard-text" class="px-5 py-3">
      <v-card-title>
        <v-dialog v-model="dialog" persistent max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#1b5e20"
              class="white--text"
              elevation="2"
              v-bind="attrs"
              v-on="on"
              >Add Code Referal</v-btn
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-select
                      v-model="editedItem.text"
                      :items="iot_selects"
                      attach
                      chips
                      label="Select Service iot"
                      required
                      ref="iots_selectReff"
                      item-text="text"
                      item-value="id"
                    >
                    </v-select>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-form>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="#1b5e20" class="white--text" elevation="2" @click="close">
                Cancel
              </v-btn>
              <v-btn color="#1b5e20" class="white--text" elevation="2" @click="save">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <div class="warning text-center">
              <header class="text-h5">Confirmation</header>
            </div>
            <v-card-title class="text-h6"
              >Are you sure you want to delete this {{ editedItem.text }} ?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="#1b5e20"
                class="white--text"
                elevation="2"
                @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn
                color="#1b5e20"
                class="white--text"
                elevation="2"
                @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-spacer></v-spacer>

        <!-- <v-text-field
              v-model="keyword"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              @input="doSearch"
            >
            </v-text-field> -->
      </v-card-title>
      <v-data-table
        ref="vDataTable"
        :headers="headers"
        :items="codereferals"
        hide-default-footer
        class="elevation-0"
      >
        <template #item="{ item, index, headers }">
          <tr>
            <td>
              {{ index + 1 + (page - 1) * 10 }}
            </td>
            <td>
              {{ item.text }}
            </td>
            <td>
              {{ item.code }}
            </td>
            <td>
              <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="text-center">
        <v-pagination
          v-model="page"
          @input="go"
          :length="lengthPage"
          :total-visible="5"
          color="#1b5e20"
        ></v-pagination>
      </div>
    </base-material-card>
    <div class="text-center">
      <v-dialog v-model="progressBar" width="100" hide-overlay>
        <v-progress-circular
          :size="60"
          color="primary"
          indeterminate
          align-center
          justify-center
        ></v-progress-circular>
      </v-dialog>
    </div>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data: () => ({
    dialogSampleCode: false,
    valid: true,
    codereferals: [],
    keyword: "",
    page: 0,
    lengthPage: 0,
    headers: [
      { text: "No", value: "id" },
      { text: "IOT Services", value: "text" },
      { text: "Code Referal", value: "code" },
      { text: "Actions", sortable: false },
    ],
    pageSampleCode: 0,
    lengthPageSampleCode: 0,
    headersSampleCode: [
      { text: "No", value: "id" },
      { text: "IOT Services", value: "text" },
      { text: "Sample Code", value: "" },
      { text: "Actions", sortable: false },
    ],
    dialog: false,
    dialogDelete: false,
    editedIndex: -1,
    editedItem: {
      text: "",
      code: "",
    },
    defaultItem: {
      text: "",
      code: "",
    },
    selectedFile: null,
    progressBar: false,
    iot_selects: [
      {
        value: "id",
      },
      {
        value: "text",
      },
    ],
  }),
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "New Code Referal" : "Edit Code Referal";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.go();
    this.iotService();
  },
  methods: {
    ...mapActions({
      setAlert: "alert/set",
      setAuth: "auth/set",
    }),

    iotService() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let url = "/service-iots";
      this.axios
        .get(url, config)
        .then((response) => {
          let { data } = response.data;
          this.iot_selects = data;
          this.editedItem.id = data[0].id;
        })
        .catch((error) => {
          console.log(error);
        });
      this.$refs.iots_selectReff.update();
    },

    go() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let url = "/codereferals?page=" + this.page;
      this.axios
        .get(url, config)
        .then((response) => {
          let { data } = response.data;
          let { meta } = response.data;
          this.codereferals = data;
          // jumlah halaman di dapat dari meta endpoint codereferals
          this.lengthPage = meta.last_page;
          this.page = meta.current_page;
        })
        .catch((error) => {
          let { responses } = error;
          console.log(responses);
        });
    },
    getColor(status) {
      if (status == "y") return "green";
      else return "red";
    },
    doSearch() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let keyword = this.keyword;
      if (keyword.length > 0) {
        this.axios
          .get("/codereferals/search/" + keyword, config)
          .then((response) => {
            let { data } = response.data;
            this.codereferals = data;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.go();
      }
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteItem(item) {
      this.editedIndex = this.codereferals.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    closeSampleCode() {
      this.dialogSampleCode = false;
    },
    deleteItemConfirm() {
      this.progressBar = true;
      let formData = new FormData();
      formData.set("id", this.editedItem.id);
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      this.axios
        .post("/codereferals/delete-permanent", formData, config)
        .then((response) => {
          let { data } = response;
          this.setAuth(data.data);
          this.setAlert({
            status: true,
            text: data.message,
            color: "success",
          });
          this.codereferals.splice(this.editedIndex, 1);
          this.closeDelete();
          this.codereferals.push(this.editedItem);
          this.go();
          this.progressBar = false;
        })
        .catch((error) => {
          let { data } = error;
          this.setAlert({
            status: true,
            text: data.message,
            color: "error",
          });
          this.codereferals.push(this.editedItem);
          this.go();
          this.progressBar = false;
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.form.validate()) {
        this.progressBar = true;
        this.progress = true;
        let formData = new FormData();
        formData.set("id", this.editedItem.text);
        formData.set("user_id", this.user.id);
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.api_token,
          },
        };

        this.axios
          .post("/codereferals/store", formData, config)
          .then((response) => {
            let { data } = response;
            this.setAuth(data.data);
            this.setAlert({
              status: true,
              text: data.message,
              color: "success",
            });
            this.codereferals.push(this.editedItem);
            this.clear();
          })
          .catch((error) => {
            let { data } = error;
            this.setAlert({
              status: true,
              text: data.message,
              color: "error",
            });
            this.clear();
          });
      }
    },
    onFileSelected(event) {
      this.selectedFile = event;
    },
    clear() {
      this.go();
      this.close();
      this.progressBar = false;
      this.progress = false;
    },
  },
};
</script>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}

.position-relative {
  position: relative;
}
</style>
